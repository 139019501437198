import { css } from "styled-components";
import media from "./media";

export const h1 = css`
  font-weight: bold;

  font-size: 3.2rem;
  line-height: 3.8rem;
  ${media.down.sm`
    font-size: 2.4rem;
  `}
`;

export const h3 = css`
  font-weight: bold;

  font-size: 1.6rem;
  line-height: 2.2rem;
`;

export const copy = css`
  font-size: 1.4rem;
  color: ${props => props.theme.colors.darkGray};
  ${media.down.sm`
    font-size: 1.2rem;
  `}
`;

export const copyBigger = css`
  font-size: 1.6rem;
  color: ${props => props.theme.colors.darkGray};
  ${media.down.sm`
    font-size: 1.2rem;
    line-height: 1;
  `}
`;
