import React, { useRef } from "react";
import { useSpring, animated, interpolate } from "react-spring";
import { useGesture } from "react-use-gesture";
import useDimensions from "react-use-dimensions";
import styled from "styled-components";
import arrowUp from "../../images/icons/arrow-up.svg";
import media from "../../styles/media";

const Wrapper = styled.div`
  position: relative;
  z-index: 100;
  ${media.up.lg`
    display: none;
  `};
`;

const Strap = styled.div`
  position: absolute;
  background-color: #f3f3f3;
  border-right: 1px solid #dddddd;
  height: 2.4rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 16rem;
  position: absolute;
  top: -2.4rem;
  left: 0;
  right: 0;
  margin: auto;
  border: 0;
  border-radius: 0.3rem 0.3rem 0 0;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
`;

function PullUp({
  children,
  hasData,
  header: Header,
  details: Details,
  className
}) {
  const headerRef = useRef(null);
  const containerRef = useRef(null);
  const [detailsRef, { height: detailsHeight }] = useDimensions();
  const [cardRef, { height: cardHeight, y: cardY }] = useDimensions();
  const [dragging, setDragging] = React.useState(false);
  const [pos, set] = React.useState([0, hasData ? 40 : -30]);
  const [headerY, setHeaderY] = React.useState(0);

  React.useLayoutEffect(() => {
    set([0, hasData ? headerRef.current.offsetHeight : -30]);
    setHeaderY(0);
  }, [hasData]);

  const bind = useGesture(
    {
      onDrag: dragState => {
        console.log(dragState);
        let newPos = pos[1] + (dragState.previous[1] - dragState.values[1]);
        if (dragState.first) {
          setDragging(true);
        }
        if (dragState.last) {
          setDragging(false);
          // if (newPos > cardHeight) {
          //   newPos = cardHeight;
          // } else if (newPos < detailsHeight) {
          //   newPos = 123;
          // }
        }

        if (window.innerHeight - newPos < 0) {
          setHeaderY(-(window.innerHeight - newPos));
        } else {
          setHeaderY(0);
        }
        newPos =
          newPos > containerRef.current.clientHeight
            ? containerRef.current.clientHeight
            : newPos;
        set([0, newPos]);
      }
      // onScroll: scrollState => {
      //   /* do stuff on scroll */
      // }
    },
    { event: { passive: false } }
  );
  const { y, bg, size, headerPosY } = useSpring({
    y: pos[1],
    headerPosY: headerY,
    size: 1,
    config: {
      mass: 1,
      tension: dragging ? 5000 : 230,
      friction: dragging ? 50 : 25
    }
  });

  const cardStyle = dragging
    ? { transform: `translate3d(0,calc(100% - ${pos[1]}px),0)` }
    : {
        transform: interpolate([y], y => `translate3d(0,calc(100% - ${y}px),0)`)
      };
  const headerStyle = dragging
    ? { transform: `translateY(${headerY}px)` }
    : { transform: interpolate([headerPosY], y => `translateY(${y}px)`) };

  return (
    <animated.div
      {...bind()}
      className={className}
      style={cardStyle}
      ref={containerRef}
    >
      <Strap>
        <img src={arrowUp} />
      </Strap>
      <animated.div ref={cardRef}>
        <Wrapper>
          <animated.div style={headerStyle} className="header" ref={headerRef}>
            <Header />
          </animated.div>
        </Wrapper>
        <div ref={detailsRef}>
          <Details />
        </div>
        {children}
      </animated.div>
    </animated.div>
  );
}

export default PullUp;
