import React from "react";
import styled from "styled-components";
import media from "../../styles/media";
import { defaultShadow } from "../styles/Shadow";
import SocialBar from "../SocialBar";

const Wrapper = styled.div`
  ${defaultShadow}
  width: 100%;
  z-index: 2;
  position: absolute;
  background: white;
  bottom: 0;
`;

const Content = styled.div`
  width: 100%;
  padding: 0.8rem 1.6rem 0 1.6rem;
`;

const Headline = styled.div`
  font-weight: bold;
  font-size: 1.2rem;
`;

const Subline = styled.div`
  margin-top: 0.8rem;
  font-size: 1.2rem;
  color: #777777;
`;

const MapSearch = styled.div`
  margin-top: 0.8rem;
`;

const StyledSocialBar = styled(SocialBar)`
  margin-top: 0.8rem;
`;

const MobileSearch = ({ search, className }) => (
  <Wrapper className={className}>
    <Content>
      <Headline>Geben Sie eine Adresse ein</Headline>
      <MapSearch>{search}</MapSearch>
      <Subline>
        Oder wählen Sie auf der Karte eine Region aus und erhalten Sie weiter
        Informationen.
      </Subline>
    </Content>
    <StyledSocialBar pullUp></StyledSocialBar>
  </Wrapper>
);

export default MobileSearch;
