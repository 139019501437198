import React from "react";

const DynamicPin = ({ color }) => {
  return (
    <svg width="64px" height="87px">
      <defs>
        <filter
          id="a"
          width="166.7%"
          height="144.4%"
          x="-33.3%"
          y="-22.2%"
          filterUnits="objectBoundingBox"
        >
          <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="4"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <path
          id="b"
          d="M24 72c16-23.163444 24-39.163444 24-48C48 10.745166 37.254834 0 24 0S0 10.745166 0 24c0 8.836556 8 24.836556 24 48z"
        />
        <circle id="d" cx="12" cy="12" r="12" />
      </defs>
      <g
        fill="none"
        fillRule="evenodd"
        filter="url(#a)"
        transform="translate(8 8)"
      >
        <mask id="c" fill="#fff">
          <use href="#b" />
        </mask>
        <use fill="#FFF" href="#b" />
        <g fill="#FFF" mask="url(#c)">
          <path d="M0 0h48v72H0z" />
        </g>
        <g transform="translate(12 12)">
          <mask id="e" fill="#fff">
            <use href="#d" />
          </mask>
          <use fill="#D8D8D8" href="#d" />
          <g fill={color} mask="url(#e)">
            <path d="M-4-4h32v32H-4z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DynamicPin;
