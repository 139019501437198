import { createGlobalStyle } from "styled-components";
import Search from "../images/icons/search.svg";
import { defaultShadow } from "./styles/Shadow";
import media from "./../styles/media";

const GlobalStyle = createGlobalStyle`

  @-ms-viewport {
    width: device-width;
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    -ms-overflow-style: scrollbar;

    // Smaller than iPhone 6
    @media (max-width: 374.98px) {
      font-size: 2.666666667vw;
    }
  }

  body {
   padding: 0;
   margin: 0;
   font-family: ${props => props.theme.fontFamily};
   font-size: 1.6rem;
   color: ${props => props.theme.colors.grayDark};
   overflow: hidden;
   position: fixed;
   height: 100vh;
   width: 100%;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  h1, h2,h3,h4,h5,h6 {
    margin: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px ${props =>
      props.theme.colors.light} inset;
  }

  .Notification {
    font-family: ${props => props.theme.fontFamily};
    color: ${props => props.theme.colors.primary};
    border-radius: 3px;
    font-weight: 900;
    padding-left: 2rem;
    &--info {
      background: ${props => props.theme.colors.secondary};
    }
    &--success {
      background: ${props => props.theme.colors.secondary};
    }
    &--error {
      background: ${props => props.theme.colors.highlight};
      color: ${props => props.theme.colors.light};
    }
  }

  .Tooltip {
    background: ${props => props.theme.colors.primary}!important;
    &.place-top {
      &:after {
        border-top-color: ${props => props.theme.colors.primary} !important;
        border-top-style: solid !important;
      }
    }
  }


  /* GEO CODER */
  .mapboxgl-ctrl-geocoder {
    &--input {
      background-image: url(${Search});
      background-repeat: no-repeat;
      vertical-align: middle;  
      background-position: right 1.5rem top 50%, 0 0;
      appearance: none;
      border: none;
      margin: 0 !important;
      padding: 1.4rem 1.6rem;
      width: 100%;
      outline: none;
      margin-top: 0.8rem;
      border: 1px solid #dddddd;
      border-radius: 4px;
      font-size: 16px;
      line-height: 26.666666667px;
      padding: 6.666666667px;
      width: 100%;
    }
    &--suggestion {
      padding: 1rem;
      cursor: pointer;
      &:hover{
        background-color: #f3f3f3
      }
    }
    &--suggestion-title {
      font-weight: bold;
      font-size: 1.6rem;
    }
    &--suggestion-address {
      font-weight: normal;
      font-size: 1.4rem;
      color: #777777;
    }
    &--icon {
      display: none;
    }
    &--pin-right {
      display: none;
    }
  }

  .suggestions {
    ${defaultShadow}
    width: 100%;
    list-style: none;
    text-indent: 0;
    padding: 0;
    margin: 0;
    position: absolute;
    background-color: white;
    border-radius: 4px;
    & > :not(:first-child){
      border-top: 1px solid #dddddd;
    }
    > .active {
      background-color: #f3f3f3
    }
    ${media.down.lg`
      top: 0;
      transform: translateY(-100%);
      `}
  }


`;

export default GlobalStyle;
