import React from "react";
import styled from "styled-components";
import Icon from "../../images/icons/close.svg";
import Dot from "../styles/Dot";
import media from "../../styles/media";
import { formatVariables } from "../utils/index";
import { defaultShadow } from "../styles/Shadow";

const Wrapper = styled.div`
  display: inline-block;
  width: 28rem;
  background-color: #ffffff;
  border-collapse: separate;
  border-radius: 4px;
  overflow: hidden;
  ${defaultShadow}
  ${media.down.lg`
    display: none;
  `};
`;

const HeadlineWrapper = styled.div`
  height: 4.1rem;
  background-color: #f3f3f3;
  padding: 0 0.8rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dddddd;
`;

const Close = styled.img`
  margin-left: auto;
  cursor: pointer;
`;

const Headline = styled.div`
  margin-left: 0.8rem;
  font-size: 1.6rem;
  font-weight: bold;
  padding-right: 1.3rem;
`;

const ContentWrapper = styled.div`
  padding: 0 0.8rem 0.8rem;
`;

const ContentHeadline = styled.div`
  font-size: 1.4rem;
  margin-top: 0.8rem;
  font-weight: bold;
`;

const ContentSubline = styled.div`
  font-size: 1.4rem;
  color: #777777;
`;

const InfoWrapper = styled.div`
  padding: 0.8rem;
  border-top: 1px solid #dddddd;
`;

const InfoText = styled.div`
  font-size: 1.4rem;
  color: #777777;
  font-style: italic;
`;

const popup = ({ region, color, onClose, infoText, info, data }) => (
  <Wrapper>
    <HeadlineWrapper>
      <Dot color={color}></Dot>
      <Headline>{region}</Headline>
      {typeof onClose === "function" && (
        <Close src={Icon} alt="Close" onClick={onClose} />
      )}
    </HeadlineWrapper>
    <ContentWrapper>
      {info.map((item, i) => (
        <div key={i}>
          <ContentHeadline>{item.headline}</ContentHeadline>
          <ContentSubline>{formatVariables(item.copy, data)}</ContentSubline>
        </div>
      ))}
    </ContentWrapper>
    {infoText && (
      <InfoWrapper>
        <InfoText>{infoText}</InfoText>
      </InfoWrapper>
    )}
  </Wrapper>
);

export default popup;
