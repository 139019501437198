import React, { useState } from "react";
import styled from "styled-components";
import { H1, Copy } from "../Text";
import media from "../../styles/media";

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  display: ${props => !props.open && "none"};
  ${media.down.lg`
    padding: 5.9rem 3.1rem;
  `}
`;

const ModalWrapper = styled.div`
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  padding: 3.2rem;
  display: flex;
  flex-direction: row;
  max-width: 73rem;
  ${media.down.lg`
    flex-direction: column;
  `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 3.2rem;
  ${media.down.lg`
    margin: 0;
    margin-top: 1.6rem;
  `}
`;

const StyledCopy = styled(Copy)`
  margin-top: 1.6rem;
  line-height: 2.4rem;
`;

const Button = styled.div`
  cursor: pointer;
  margin-top: 3.2rem;
  background: ${props => props.theme.colors.highlight};
  border-radius: 4px;
  padding: 0 1.6rem;
  display: flex;
  align-items: center;
  min-height: 4.8rem;
  color: white;
  font-size: 1.4rem;
  font-weight: bold;
  align-self: flex-start;
  ${media.down.lg`
    align-self: auto;
    justify-content: center;
  `}
`;

const Logo = styled.img`
  max-height: 22.4rem;
  flex-shrink: 0;
  ${media.down.lg`
    align-self: center;
    `}
  ${media.down.sm`
    max-height: 11.2rem;
  `}
`;

const Modal = ({ logo, headline, copy, onClose, open }) => {
  return (
    <Wrapper open={open}>
      <ModalWrapper>
        <Logo src={logo}></Logo>
        <Content>
          <H1>{headline}</H1>
          <StyledCopy bigger dangerouslySetInnerHTML={{ __html: copy }} />
          <Button onClick={onClose}>Karte erkunden</Button>
        </Content>
      </ModalWrapper>
    </Wrapper>
  );
};

export default Modal;
