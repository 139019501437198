import styled, { css } from 'styled-components'
import * as text from '../../styles/text'

const defaultStyle = css`
  color: black;
  /* margin: 0; */
`

export const Copy = styled.span`
  ${defaultStyle}
  ${props => props.bigger ? 
  text.copyBigger : text.copy
  }
`
export const H1 = styled.span`
  ${defaultStyle}
  ${text.h1}
`
export const H3 = styled.span`
  ${defaultStyle}
  ${text.h3}
`