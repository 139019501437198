import React from "react";
import styled from "styled-components";
import Geocoder from "react-map-gl-geocoder";
import pointInPolygon from "@turf/boolean-point-in-polygon";
import { point } from "@turf/helpers";
import { featureEach } from "@turf/meta";
import { GeoJsonLayer } from "deck.gl";
import { Container, Row, Col } from "styled-bootstrap-grid";
import { H3 } from "../Text";
import { h3 } from "../../styles/text";
import media from "../../styles/media";

const StyledContainer = styled(Container)`
  text-align: center;
`;

const StyledImage = styled.div`
  display: none;
  ${media.up.sm`
    display: block;
  `}
`;

const InputWrapper = styled.div`
  text-align: left;
`;

const ImageTextWrapper = styled.div`
  display: flex;
  align-items: center;
  ${media.up.sm`
    flex-direction: row;
    `}
  ${media.up.sm`
    flex-direction: column;
  `}
`;

const Text = styled.h3`
  ${h3};
  ${media.up.sm`
    text-align: left;
  `}
  ${media.up.sm`
    text-align: center;
  `}
`;

export default class MapSearch extends React.Component {
  state = {
    componentDidMount: false
  };

  searchRef = React.createRef();

  componentDidMount() {
    this.setState({ componentDidMount: true });
  }

  handleGeocoderViewportChange = viewport => {
    const { geoJson } = this.props;
    let foundFeature = null;
    featureEach(geoJson, (feature, index) => {
      if (
        pointInPolygon(point([viewport.longitude, viewport.latitude]), feature)
      ) {
        foundFeature = feature;
      }
    });

    if (foundFeature) {
      this.setState({ foundFeature: true });
      this.props.onSelectRegion(foundFeature.properties);
      this.props.onPinCoordinatesChange({
        longitude: viewport.longitude,
        latitude: viewport.latitude
      });
      // this.setState(prevState => ({
      //   ...prevState,
      //   viewport: {
      //     ...this.getBoundingBoxViewport(foundFeature),
      //     width: this.containerRef.current.clientWidth,
      //     height: this.containerRef.current.clientHeight
      //   },
      //   selectedFeature: foundFeature,
      //   addrressLoaction: {
      //     latitude: viewport.latitude,
      //     longitude: viewport.longitude
      //   },
      //   selectedLayer: new GeoJsonLayer({
      //     id: "geojson-layer2",
      //     data: foundFeature,
      //     pickable: false,
      //     stroked: true,
      //     filled: false,
      //     lineWidthScale: 20,
      //     lineWidthMinPixels: 2,
      //     getLineColor: [255, 255, 255, 255],
      //     getLineWidth: 8
      //   })
      // }));
    }
  };

  render() {
    const { mapRef, onViewportChange } = this.props;
    const { componentDidMount, foundFeature } = this.state;
    console.log("mapRef in search", mapRef);
    return (
      <InputWrapper mt={4} mb={5} ref={this.searchRef}>
        {componentDidMount && mapRef && mapRef.current && (
          <Geocoder
            placeholder="Ihre Adresse"
            mapRef={mapRef}
            inputValue={foundFeature && ""}
            containerRef={this.searchRef}
            onViewportChange={this.handleGeocoderViewportChange}
            mapboxApiAccessToken="pk.eyJ1IjoiY3B0Y29tcGlsZXIiLCJhIjoiY2pydXRzNDUwMGNxdzQ3bm1hZjJhejM4aSJ9.w9lv0mKtJQFjuyou42q2tw"
            position="top-left"
            countries="de"
          />
        )}
      </InputWrapper>
    );
  }
}
