import React from "react";
import styled from "styled-components";
import Dot from "../styles/Dot";
import { H3, Copy } from "../Text";
import closeIcon from "../../images/icons/close.svg";

const Header = styled.div`
  background-color: #f3f3f3;
  border-bottom: 1px solid #dddddd;
  display: flex;
  height: 7.8rem;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
`;

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1.8rem;
  border-right: 1px solid #dddddd;
  cursor: pointer;
`;

const HeaderData = styled.div`
  padding-left: 1.6rem;
  width: 100%;
  cursor: pointer;
`;

const Region = styled.div`
  margin-left: 1.6rem;
  display: flex;
  flex-direction: column;
`;

const RegionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const StyledH3 = styled(H3)`
  line-height: 1;
`;

const StyledCopy = styled(Copy)`
  margin-top: 0.4rem;
`;

const RegionHead = ({ onClose, onHeaderClick, color, region, province }) => (
  <Header>
    <CloseButton onClick={onClose}>
      <img src={closeIcon} />
    </CloseButton>
    <HeaderData onClick={onHeaderClick}>
      <RegionWrapper>
        <Dot color={color}></Dot>
        <Region>
          <StyledH3>{region}</StyledH3>
          <StyledCopy>{province}</StyledCopy>
        </Region>
      </RegionWrapper>
    </HeaderData>
  </Header>
);

export default RegionHead;
