import React from "react";
import styled from "styled-components";
import facebook from "../../images/icons/facebook.svg";
import twitter from "../../images/icons/twitter.svg";
import share from "../../images/icons/share.svg";

const Social = styled.div`
  position: ${props => (props.fixed ? "fixed" : "unset")};
  bottom: 0;
  width: ${props => (props.pullUp ? "100%" : "36rem")};
  height: 4.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  > img {
    cursor: pointer;
  }
`;

const SocialField = styled.a`
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: ${props => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SocialBar = ({ pullUp, fixed, className }) => (
  <Social pullUp={pullUp} fixed={fixed} className={className}>
    <SocialField
      rel="noopener noreferrer"
      color="#3B5998"
      href={
        "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href
      }
      target="_blank"
    >
      <img src={facebook}></img>
    </SocialField>
    <SocialField
      target="_blank"
      rel="noopener noreferrer"
      color="#00ACED"
      href={
        "https://twitter.com/intent/tweet?text=" +
        encodeURI("Guck mal was ich gerade gesehen habe: ") +
        window.location.href
      }
    >
      <img src={twitter}></img>
    </SocialField>
    <SocialField
      color="#777777"
      href={`mailto:?body=${window.location.href}&subject=Guck mal was ich gerade gesehen habe`}
    >
      <img src={share}></img>
    </SocialField>
  </Social>
);

export default SocialBar;
