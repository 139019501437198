function hexToRGB(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
      ]
    : null;
}

export function getFieldColor(current, r) {
  let color = r.na;
  current = parseFloat(current);
  if (current < r.ranges[0].from) {
    return r.lower_than_first_range;
  }
  r.ranges.forEach((range, i) => {
    if (
      (i < r.ranges.length - 1 &&
        (current >= range.from && current <= r.ranges[i + 1].from)) ||
      current >= range.from
    ) {
      color = range.color;
    }
  });
  return color;
}
const re = /##([a-zA-Z_-\d]*)##/g;

export function formatVariables(text, data) {
  re.lastIndex = 0;
  let match = re.exec(text);
  while (match != null) {
    text = text.replace(match[0], data[match[1]]);
    match = re.exec(text);
  }
  return text;
}
