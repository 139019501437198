import React from "react";
import styled from "styled-components";
import Chart from "../Chart";
import { H3, Copy } from "../Text";
import closeIcon from "../../images/icons/close.svg";
import { formatVariables } from "../utils/index";
import Region from "../Region";
import searchIcon from "../../images/icons/search.svg";
import { defaultShadow } from "../styles/Shadow";
import SocialBar from "../SocialBar";

const InputLabel = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
`;

const Input = styled.div`
  margin-top: 0.8rem;
`;

const SearchBox = styled.div`
  padding: 1.6rem 1.4rem 1.6rem 1.3rem;
  background-color: #f3f3f3;
  border-bottom: 1px solid #dddddd;
`;

const Wrapper = styled.div`
  ${defaultShadow}
  /* z-index: 999; */
  width: ${props => (props.pullUp ? "100%" : "36rem")};
  flex-shrink: 0;
  /* max-height: 100%; */
  overflow: scroll;
  background-color: #ffffff;
  position: relative;
`;

const Head = styled.div`
  padding: 1.6rem;
  background-color: #f3f3f3;
  border-bottom: 1px solid #dddddd;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Img = styled.img`
  width: 8.8rem;
  height: auto;
`;

const Headline = styled.div`
  font-size: 3.2rem;
  font-weight: bold;
`;

const Subline = styled.div`
  font-size: 1.4rem;
  color: #777777;
  margin-top: 0.8rem;
`;

const Text = styled.div`
  margin-left: 1.6rem;
`;

const Infotext = styled.div`
  padding-top: 3.1rem;
  padding-left: 3.2rem;
  max-width: 29.6rem;
`;

const Content = styled.div`
  margin-top: 3.2rem;
  padding: 0 1.6rem 8rem;
  > div {
    &:not(:first-of-type) {
      margin-top: 3.2rem;
    }
  }
`;

const HeaderData = styled.div`
  padding-left: 1.6rem;
  width: 100%;
`;

const RegionData = styled.div`
  margin-top: 3.2rem;
  padding-bottom: 3.2rem;
  border-bottom: 1px solid #dddddd;
`;

const Data = styled.div`
  &:not(:first-child) {
    margin-top: 1.2rem;
  }
`;

const DataHeadline = styled.div`
  font-weight: bold;
  font-size: 1.6rem;
`;

const DataSubline = styled.div`
  margin-top: 0.3rem;
  color: #777777;
`;

const Sidebar = ({
  region,
  province,
  color,
  logo,
  headline,
  subline,
  charts,
  regionProperties,
  onClose,
  className,
  info,
  data,
  pullUp,
  search
}) => (
  <Wrapper pullUp={pullUp} className={className}>
    {logo && (
      <Head>
        <Img src={logo} alt="Logo"></Img>
        <Text>
          <Headline>{headline}</Headline>
          <Subline>{subline}</Subline>
        </Text>
      </Head>
    )}
    {region && (
      <>
        {!pullUp && (
          <Region
            onClose={onClose}
            color={color}
            region={region}
            province={province}
          ></Region>
        )}
        <Content>
          <RegionData>
            {info.map((item, i) => (
              <Data key={i}>
                <DataHeadline>{item.headline}</DataHeadline>
                <DataSubline>{formatVariables(item.copy, data)}</DataSubline>
              </Data>
            ))}
          </RegionData>
          {charts.map((chart, i) => (
            <Chart
              headline={chart.headline}
              subline={chart.subline}
              bars={chart.bars}
              key={i}
              barValue={index => regionProperties[`${chart.name}_${index}`]}
            ></Chart>
          ))}
        </Content>
      </>
    )}
    <SearchBox style={{ display: `${!pullUp && !region ? "block" : "none"}` }}>
      <InputLabel>Geben Sie eine Addresse ein</InputLabel>
      <Input>{search}</Input>
    </SearchBox>
    {!region && (
      <Infotext>
        <Copy>
          Wählen Sie auf der Karte eine Region aus und erhalten Sie weitere
          Informationen.
        </Copy>
      </Infotext>
    )}
    <SocialBar pullUp={pullUp} fixed></SocialBar>
  </Wrapper>
);

export default Sidebar;
