import MAP_STYLE from "./style.json";

// For more information on data-driven styles, see https://www.mapbox.com/help/gl-dds-ref/
export const getDataLayer = () => {
  return {
    id: "data",
    source: "regions",
    type: "fill",
    interactive: true,
    paint: {
      "fill-color": ["get", "fieldColor"],
      "fill-opacity": 0.9
    }
  };
};

export const outlineLayer = {
  id: "outlineLayer",
  source: "regions",
  interactive: false,
  type: "line",
  paint: {
    "line-color": "#6E6E6E",
    "line-width": 0.5
  }
};

export const selectedRegionLayer = {
  id: "selectedRegion",
  source: "selectedRegion",
  type: "line",
  interactive: false,
  paint: {
    "line-color": "#fff",
    "line-width": 3
  }
};

export const defaultMapStyle = MAP_STYLE;
