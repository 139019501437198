import React from "react";
import styled, { css } from "styled-components";
import { H3, Copy } from "../Text";

const Head = styled.div`
  display: flex;
  flex-direction: column;
`;

const BarsWrapper = styled.div`
  margin-top: 1.6rem;
`;

const BarLabel = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Bar = styled.div`
  position: relative;
  height: 1.6rem;
  margin-top: 0.4rem;
  width: 100%;
  background-color: #f3f3f3;
  &:before {
    content: " ";
    position: absolute;
    height: 1.6rem;
    width: ${props => props.percentage}%;
    opacity: 1;
    background-color: ${props => props.color};
  }
`;

const BarText = styled.p`
  margin: 0;
  font-size: 1.2rem;
  color: ${props => props.theme.colors.darkGray};
  ${props =>
    props.bold &&
    css`
      font-weight: bold;
    `}
`;

const BarWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 0.8rem;
  }
`;

const chart = ({ headline, subline, bars, barValue }) => {
  return (
    <div>
      <Head>
        <H3>{headline}</H3>
        <Copy>{subline}</Copy>
      </Head>

      <BarsWrapper>
        {bars.map((bar, i) => (
          <BarWrapper key={i}>
            <BarLabel>
              <BarText>{barValue(i)}%</BarText>
              <BarText bold>{bar.label}</BarText>
            </BarLabel>
            <Bar color={bar.color} percentage={barValue(i)}></Bar>
          </BarWrapper>
        ))}
      </BarsWrapper>
    </div>
  );
};

export default chart;
