import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import axios from "axios";

import Map from "./components/Map";
import InfoBox from "./components/InfoBox";
import Popup from "./components/Popup";
import Sidebar from "./components/Sidebar";
import Modal from "./components/Modal";
import PullUp from "./components/PullUp";
import Region from "./components/Region";
import MapSearch from "./components/MapSearch";
import MobileSearch from "./components/MobileSearch";
import SocialBar from "./components/SocialBar";

import { getFieldColor } from "./components/utils";
import media from "./styles/media";
import arrowUp from "./images/icons/arrow-up.svg";

window.onresize = function() {
  document.body.style.height = `${window.innerHeight}px`;
};
window.onresize();

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-height: 100vh;
  overflow: hidden;
`;

const MapWrapper = styled.div`
  width: 100%;
  height: ${props => document.body.style.height};
`;

const StyledSidebar = styled(Sidebar)`
  display: none;
  ${media.up.lg`
    display: block;
  `};
`;

const StyledPullUp = styled(PullUp)`
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: white;
  z-index: 100;
  ${media.up.lg`
    display: none;
  `}
`;

const StyledMobileSearch = styled(MobileSearch)`
  display: none;
  ${media.down.lg`
    display: block;
  `}
`;

const Details = styled.div`
  padding-top: 2.4rem;
  height: ${props => `calc(${document.body.style.height} + 2.4rem)`};
  width: 100%;
  /* overflow: scroll; */
  position: absolute;
  z-index: 100;
  top: 0;
  transition: 0.3s transform;
  transform: translateY(
    ${props => {
      switch (props.height) {
        case 0:
          return document.body.style.height;
        case 1:
          return `calc(${document.body.style.height} - 10.2rem)`;
        case 2:
          return "-2.4rem";
        default:
          return 0;
      }
    }}
  );
  ${media.up.lg`
    display: none;
  `}
`;

const DetailsContent = styled.div`
  overflow: scroll;
  height: 100%;
`;

const Strap = styled.div`
  position: absolute;
  background-color: #f3f3f3;
  border-right: 1px solid #dddddd;
  height: 2.4rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 16rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  border: 0;
  border-radius: 0.3rem 0.3rem 0 0;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
`;

function App() {
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [data, setData] = useState(null);
  const [mapRef, setMapRef] = useState(null);
  const [pinCoordinates, setPinCoordinates] = useState(null);
  const [detailsHeight, setDetailsHeight] = useState(0);
  const [modalOpen, setModalOpen] = useState(true);
  const onEscape = e => {
    if (e.keyCode === 27) {
      setSelectedRegion(null);
    }
  };
  document.addEventListener("keydown", onEscape, false);
  useEffect(() => {
    async function getData() {
      await axios
        .get(process.env.REACT_APP_CONFIG_URL, {
          cors: true
        })
        .then(response => {
          setData(response.data);
        });
    }
    getData();
  }, []);
  const unsetRegion = () => {
    setSelectedRegion(null);
    setPinCoordinates(null);
    setDetailsHeight(0);
  };
  const setRegion = region => {
    setSelectedRegion(region);
    setPinCoordinates(null);
    setDetailsHeight(1);
  };
  return (
    <div className="App">
      {!data ? (
        <div>LOADING</div>
      ) : (
        <Wrapper>
          <Modal
            headline={data.properties.headline_intro}
            copy={data.properties.copy}
            logo={data.properties.image}
            open={modalOpen}
            onClose={() => setModalOpen(false)}
          ></Modal>
          {!modalOpen && (
            <StyledSidebar
              headline={data.properties.headline_sidebar}
              subline={data.properties.subline}
              region={selectedRegion ? selectedRegion.GEN : null}
              color={selectedRegion ? selectedRegion.fieldColor : null}
              province={selectedRegion ? selectedRegion.Bundesland : null}
              logo={data.properties.image}
              charts={data.properties.charts}
              regionProperties={selectedRegion}
              onClose={unsetRegion}
              data={selectedRegion}
              info={data.properties.popup_sections}
              search={
                <MapSearch
                  // onViewportChange={setMapViewport}
                  mapRef={mapRef}
                  geoJson={data}
                  onSelectRegion={setRegion}
                  onPinCoordinatesChange={setPinCoordinates}
                />
              }
            ></StyledSidebar>
          )}

          <MapWrapper>
            <Map
              geoJson={data}
              range={data.properties.value_range}
              onSelectRegion={setRegion}
              selectedRegionId={selectedRegion ? selectedRegion.rs : null}
              selectedRegion={selectedRegion}
              // onUserInteraction={() => setSelectedRegion(null)}
              passRef={ref => setMapRef(ref)}
              pinCoordinates={pinCoordinates}
            />
            )}
            <InfoBox range={data.properties.value_range}></InfoBox>
          </MapWrapper>
          {!modalOpen && (
            <Details height={detailsHeight}>
              <Strap onClick={() => setDetailsHeight(2)}>
                <img src={arrowUp} />
              </Strap>
              <DetailsContent>
                <Region
                  onHeaderClick={() => setDetailsHeight(2)}
                  onClose={unsetRegion}
                  color={selectedRegion ? selectedRegion.fieldColor : null}
                  province={selectedRegion ? selectedRegion.Bundesland : null}
                  region={selectedRegion ? selectedRegion.GEN : null}
                ></Region>
                <div
                  style={{
                    background: "white",
                    minHeight: document.body.style.height
                  }}
                >
                  <Sidebar
                    pullUp
                    region={selectedRegion ? selectedRegion.GEN : null}
                    color={selectedRegion ? selectedRegion.fieldColor : null}
                    province={selectedRegion ? selectedRegion.Bundesland : null}
                    charts={data.properties.charts}
                    regionProperties={selectedRegion}
                    onClose={unsetRegion}
                    data={selectedRegion}
                    info={data.properties.popup_sections}
                  ></Sidebar>
                </div>
              </DetailsContent>
            </Details>
          )}
          {!selectedRegion && !modalOpen && (
            <StyledMobileSearch
              search={
                <MapSearch
                  mapRef={mapRef}
                  geoJson={data}
                  onSelectRegion={setRegion}
                  onPinCoordinatesChange={setPinCoordinates}
                />
              }
            ></StyledMobileSearch>
          )}
        </Wrapper>
      )}
    </div>
  );
}

export default App;
